
body{
    color: #000;
}

.drawing h1{
    margin-left: 2rem;
    margin-top: 2rem;
    text-shadow: 1px 1px 5px #62A357;
}
 
.canvas{
    position: fixed;
    top: 10vh;
    right: 10vw;
    cursor: crosshair;
} 
.canvas h4{
    text-align: center;
}

.insideCanvas{
    width: 300px;
    height: 400px; 
    background-color: white;
    border: solid 1px #62A357;
    box-shadow: 1px 1px 20px #62A357;
}

#colorPicker{
    position: relative;
    float: right;
}

.explanation{
    box-sizing: border-box;
    position: static;
    z-index: 5;
    width: 50vw;
    border: none; 
    transform-origin: top;
}
.explanation p{
    padding: 0rem 3rem 0rem 3rem;
    font-size: 0.95rem;
    margin: 0;
    z-index: 5;
    margin-top: 1rem;
}

@keyframes in{
    0% { transform: scaleY(0)}
    100% { tranform: scaleY(1)}
}
@keyframes out{
    0% { transform: scaleY(1) }
    100% { transform: scaleY(0) }
}

.close{
    position: relative;
    left: 2rem;
    width: min-content;
}
.close h4{
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.buttons{
    position: absolute;
    bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50vw;
    margin-left: 2rem;
    text-shadow: 1px 1px 5px #62A357;
}
.buttons h3:hover{
    animation: wiggle 0.9s ease infinite;
}
@keyframes wiggle{
    0% { transform: translateX(0px)}
    50% { transform: translateX(5px)}
    100% { transform: translateX(0px)}
}

/* POP UP WINDOW  */

.disableBg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: transparent;
}
.popUp{
    position: absolute;
    padding: 2rem;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: 0rem;
    width: 60vw;
    border: solid 1px #62A357;
    background-color: white;
    box-shadow: 1px 1px 20px #62A357;
    z-index: 30;
}
.buttonsPopUp{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    text-shadow: 1px 1px 5px #62A357;
}
.buttonsPopUp h4{
    padding: 0.5rem 2rem 0.5rem 2rem;
}



@media only screen and (max-width: 600px){
    .canvas{
        top: 8rem;
    }
    .buttons{
        width: 85vw;
    }
    .explanation{
        position: absolute;
        width: 85vw;
        left: 7.5vw;
    }
    .explanation p{
        background-color: white;
        box-shadow: 1px 1px 20px #62A357;
        padding: 1rem;
        /* padding: 0; */
        margin: 0;
        overflow: scroll;
        max-height: 70vh;
    }
    .popUp{
        background-color: white;
        box-shadow: 1px 1px 20px #62A357;
        padding: 1rem;
        top: 50%;
    }
    .close{
        position: fixed;
        left: 77vw;
        top: 5vh;
    }
    .close h4{
        /* border: solid 0.5px white; */
        width: max-content;
        padding: 0.5rem 2rem 0.5rem 1rem;
        /* border-radius: 1rem; */
        /* background: white; */
        /* background-image: url("../style/testfabric2.png"); */
        /* background-size: 100% 100%; */
        /* filter:drop-shadow(0px 1px 2px rgba(255,255,255,0.5)); */
    }
}