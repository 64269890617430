.mugCss{
    overflow: hidden;
    height: 100vh;
    padding: 2rem;
    box-sizing: border-box;
} 

.text{
    height: 80vh;
    max-height: max-content;
    width: 30vw;
    padding-left: 1rem;
    padding-right: 2rem;
    position: absolute;
    left: 67vw;
    top: 10vh;
    overflow: scroll;
    border-bottom: solid #62A357 1px;
    box-shadow: inset 0 -10px 10px -10px #62A357;
}

.h1mug{
    width: 60vw;
    text-shadow: 1px 1px 5px #62A357;
    z-index: 19;
    position:relative;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

#mugCer{
    position: absolute;
    top: 5vh;
    height: 15vh;
    left: 34vw;
}
#mugCer:hover{
    animation: rotation 5s linear infinite;
}

@keyframes rotation{
    from { transform: rotate(0deg) }
    to { transform: rotate(359deg)}
}

#next{
    z-index: 20;
    position: relative;
    width: 40vw;
    display: flex;
}
.nextButton{
    cursor: pointer;
    text-shadow: 1px 1px 5px #62A357;
    position:relative;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    animation: wiggle 0.9s ease infinite;
}
.nextButton:hover{
    animation: wiggle 0.9s ease infinite;
}
#skipButton{
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1.5rem 3rem 0.5rem 2rem;
    text-shadow: 1px 1px 5px #62A357;
    cursor: pointer;
}

#infoButton{
    z-index: 20;
    position: absolute;
    bottom: 5vh;
    left: 0;
    padding: 1.5rem 3rem 0.5rem 2rem;
    text-shadow: 1px 1px 5px #62A357;
    cursor: pointer;
    display: none;
}

#skipButton h3:hover{
    animation: wiggle 0.9s ease infinite;
}
@keyframes wiggle{
    0% { transform: translateX(0px)}
    50% { transform: translateX(5px)}
    100% { transform: translateX(0px)}
}

.threed{
    height: 5vh;
    position: absolute;
    left: 34vw;
    top: 82vh;
    animation: rotation 5s linear infinite reverse;
}

@media only screen and (max-width: 600px){
    .text{
        width: 75vw;
        top: 25vh;
        left: 8vw;
        background-color: white;
        height: 40vh;
        position: absolute;
        box-shadow: 1px 1px 20px #62A357;
        padding: 1rem;
        z-index: 20;
    } 
    .h1mug{
        width: 90vw;
    }
    #mugCer{
        left: 70vw;
    }
    #next{
        width: 50vw;
    }
    #skipButton{
        padding: 1rem;
    }
    .mugCss img{
        left: 8vw;
        top: 83vh;
    }
    #infoButton{
        display: block;
    }
    .threed{
        display: none;
    }
    #next{
        position: absolute;
        left: 50vw;
        top: 75vh;
        transform: translate(-50%,-50%);
        width: max-content;
    }
}