
@font-face{
  font-family: syneH;
  src: url(style/SyneTactile-Regular.ttf);
}
@font-face{
  font-family: victor;
  src: url(style/VictorMono-Regular.ttf);
}
@font-face{
  font-family: monotalic;
  src: url(style/Monotalic-Light.ttf);
}
@font-face{
  font-family: monotalic-bold;
  src: url(style/Monotalic-Bold.ttf);
}

body {
  margin: 0;
  font-family: "monotalic";
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background-color: #EEF3ED;
}

a{
  color: black;
  text-decoration: none;
}
a:visited{
  color: black;
}

h1{
  font-family: "syneH";
  font-size: 8rem;
  font-weight: normal;
  padding: 0;
  margin: 0;
  line-height: 7rem;
}

h3{
  font-family: "syneH";
  font-size: 5rem;
  padding: 0;
  margin: 0;
  font-weight: normal;
  line-height: 4rem;
  color: black;
}

h4{
  font-family: "syneH";
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.click{
  cursor: pointer;
}


@media only screen and (max-width: 600px){
  h1{
    font-size: 3rem;
    line-height: 2.5rem;
  }
  h3{
    font-size: 2.5rem;
    line-height: 2rem;
  }
}